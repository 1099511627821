import paymentMethods from "@/constants/payment-methods";
import moment from "moment";

const expenseCreateForm = {
  1: {
    0: {
      type: "title",
      title: "Vamos Registrar uma nova despesa!",
    },
    1: {
      subtitle:
        "Aqui você irá cadastrar os pagamentos que você faz para manter sua academia em funcionamento.",
      type: "subtitle",
    },
    2: {
      type: "subtitle",
      subtitle:
        "Despesas como aluguel, IPTU, água, energia, internet e equipamentos.",
    },
    3: {
      type: "image",
      image: "no-members",
    },
    4: {
      type: "buttons",
      buttons: ["next"],
    },
  },
  2: {
    0: {
      type: "title",
      title: "Dados da Despesa",
    },
    1: {
      subtitle: "Aqui você irá cadastrar dados da sua despesa.",
      type: "subtitle",
    },
    3: {
      type: "form",
      name: "expense-create",
      form: {
        reference: {
          value: moment().format("YYYY-MM"),
          label: "Mês de Referência",
          name: "reference",
          inputType: "date",
          dateType: "month",
        },
        date: {
          value: moment().format("YYYY-MM-DD"),
          label: "Data do Pagamento",
          name: "date",
          inputType: "date",
        },
        method: {
          value: "pix",
          inputType: "select",
          items: paymentMethods,
          label: "Método de Pagamento",
          name: "method",
        },
        price: {
          value: "",
          label: "Valor",
          inputType: "money",
          name: "price",
        },
        description: {
          value: "",
          label: "Descrição",
          inputType: "text",
          name: "description",
        },
      },
    },
    4: {
      type: "buttons",
      buttons: ["previous", "next", "submit"],
    },
  },
};

export default expenseCreateForm;
