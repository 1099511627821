<script>
import ExpenseCreateTemplate from "../templates/ExpenseCreateTemplate.vue";
import { mapGetters } from "vuex";

export default {
  components: { ExpenseCreateTemplate },
  name: "ExpenseCreatePage",
  data: () => ({
    form: {},
  }),
  props: {
    id: String,
  },
  computed: {
    ...mapGetters({ currentAcademy: "academy/currentAcademy" }),
  },
  methods: {
    async submit(payload) {
      let newExpense = {
        date: payload.date,
        reference: payload.reference,
        method: payload.method,
        price: Number(payload.price),
        academyId: this.currentAcademy.id,
        type: "expense",
        description: payload.description,
      };
      await this.$store.dispatch("billing/create", newExpense);
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <expense-create-template @cancel="cancel" @submit="submit" />
</template>
